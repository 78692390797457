import React, { useContext, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import AOS from "aos";
import "aos/dist/aos.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import SocketContext from "../context/SocketContext";

// import components
import Footer from "../components/Footer";
import Navbar from "../components/Navbar";
import Faq from "../components/Home/Faq";
import NavbarInner from "../components/Navbar-Inner";
import NavbarFixed from "../components/Navbar-Fixed";
import StakingCal from "../components/Staking/StakingCal";
import { useTheme } from "../components/ThemeContext";

//import lib
import isEmpty from "../lib/isEmpty";
import { toFixedDown } from "../lib/roundOf";

//import api
import { getHomePage } from "../api/users";
import { getPairList, setPairList } from "../api/tradeAction";

// Scroll to Top
function ScrollToTopOnMount() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return null;
}

export default function Home() {
  const { theme } = useTheme();

  //redux
  const { isAuth } = useSelector((state) => state.auth);
  const pairData = useSelector((state) => state.tradePair);
  const pairListData = useSelector((state) => state.pairList);
  const currency = useSelector((state) => state.currency);
  const priceConversion = useSelector((state) => state.priceConversion);

  //hooks
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const socketContext = useContext(SocketContext);

  useEffect(() => {
    AOS.init();
  }, []);

  // statew
  const [duppairlists, setduppairlists] = useState([]);
  const [data, setData] = useState({});
  const [currencyData, setCurrency] = useState({ data: [], count: 0 });

  //function
  const getHomeDetailas = async () => {
    try {
      const { status, result } = await getHomePage();
      if (status) {
        setData(result);
      }
    } catch (err) {
      console.log(err, "error");
    }
  };

  const handleAsset = () => {
    try {
      let tempArr = [];
      currency?.length >= 0 &&
        currency.map((item, index) => {
          let PriceCnv = priceConversion.find(
            (el) =>
              el.baseSymbol == item.currencySymbol && el.convertSymbol == "USD"
          );
          tempArr.push({
            image: item.image,
            coin: item.currencySymbol,
            decimals: item.decimals,
            USDValue: !isEmpty(PriceCnv?.convertPrice)
              ? parseFloat(PriceCnv.convertPrice)
              : 1,
          });
        });
      setCurrency({
        data: tempArr,
        count: tempArr.length,
      });
    } catch (err) {
      console.log("err:------ ", err);
    }
  };

  const fetchPairList = async () => {
    try {
      const { status, result } = await getPairList();
      if (status == "success") {
        setPairList(result, dispatch);
      }
    } catch (err) {
      console.log(err, "error");
    }
  };

  // useEffect(() => {
  //   getHomeDetailas();
  // }, []);

  useEffect(() => {
    if (pairListData && pairListData.length > 0) {
      var newarray = [];
      for (var i = 0; i < pairListData.length; i++) {
        if (pairListData[i].secondCurrencySymbol) {
          newarray.push(pairListData[i]);
        }
      }
      setduppairlists(newarray);
    }
  }, [pairListData]);

  useEffect(() => {
    // fetchPairList();
    // return () => {
    //   socketContext.socket.off("marketPrice");
    // }
  }, []);

  useEffect(() => {
    if (!isEmpty(currency) && !isEmpty(priceConversion)) {
      handleAsset();
    }
  }, [currency, priceConversion]);

  useEffect(() => {
    let pathname = location.pathname;
    if (pathname == "/") {
      socketContext.socket.on("marketPrice", (result) => {
        setduppairlists((el) => {
          let pairList = [];
          el.map((item) => {
            if (item._id == result.pairId) {
              pairList.push({
                ...item,
                ...{
                  markPrice: result.data.markPrice,
                  change: result.data.change,
                },
              });
            } else {
              pairList.push(item);
            }
          });

          return pairList;
        });
      });
    }
  }, [pairData]);

  var ticker_settings = {
    dots: false,
    navs: true,
    infinite: true,
    slidesToShow: currencyData.count < 2 ? 1 : (currencyData.count > 3 ? 3 : currencyData.count),
    slidesToScroll: 1,
    // autoplay: true,
    // autoplaySpeed: 2000,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: currencyData.count > 3 ? 3 : currencyData.count,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: currencyData.count > 2 ? 2 : 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 576,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <div className="overflow-hidden" >
      {/* <ScrollToTopOnMount /> */}
      {isAuth ? <NavbarInner /> : <Navbar />}
      <div className="page_header">
        <div className="container">
          <div className="page_header_inner">
            <div className="row align-items-center">
              <div className="col-lg-9 mx-auto">
                {/* <div className="sub_head">Unleash your earning potential</div> */}
                <div className="ban_head" >
                  <h1 data-aos="fade-up" data-aos-duration="1000">
                    Release   <img
                      src={require("../assets/images/ban_txt_img.png")}
                      className="img-fluid"
                      alt="Light Image"
                    />     your staked crypto
                    {/* <span> </span> */}
                  </h1>
                </div>
              </div>
            </div>

            <div className="blw_ctn" >
              <p data-aos="fade-up" data-aos-duration="1000">
                {/* If you have fallen victim to scammers, reach out to us and our team of experts will help you retrieve your money. */}
                AbandonedCoins allows you to seamlessly unlock and manage your staked cryptocurrency, providing a user-friendly interface to easily withdraw or adjust your staked assets.
              </p>
              <button
                onClick={() => navigate(isAuth ? "/staking" : "/register")}
                className="primary_btn"
                data-aos="fade-up"
                data-aos-duration="1000"
              >
                {isAuth ? "Stake Now!" : "Release Now!"}
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="main_wrapper">
        {/* <section className="section countdown_section">
          <div className="container">
            <div className="countdown_panel">
              <div
                className="countdown_single"
                data-aos="fade-up"
                data-aos-duration="1000"
                data-aos-delay="100"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="44"
                  height="48"
                  viewBox="0 0 44 48"
                  fill="none"
                >
                  <path
                    d="M2 8.59994C2 4.95549 4.97778 2 8.66667 2H35.3333C39.0222 2 42 4.95549 42 8.59994V32.8445C42 36.4889 39.0222 39.4444 35.3333 39.4444H33.6444C31.8666 39.4444 30.1778 40.1333 28.9333 41.3777L25.1333 45.1333C23.3999 46.8444 20.5778 46.8444 18.8445 45.1333L15.0444 41.3777C13.8 40.1333 12.0889 39.4444 10.3333 39.4444H8.66667C4.97778 39.4444 2 36.4889 2 32.8445V17.6223"
                    stroke="#1E1E1E"
                    stroke-width="2.5"
                    stroke-miterlimit="10"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M22.0001 19.7774C24.8597 19.7774 27.1779 17.4592 27.1779 14.5996C27.1779 11.7399 24.8597 9.42188 22.0001 9.42188C19.1405 9.42188 16.8223 11.7399 16.8223 14.5996C16.8223 17.4592 19.1405 19.7774 22.0001 19.7774Z"
                    stroke="#FF8A00"
                    stroke-width="2.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M30.8891 32.3557C30.8891 28.3557 26.9113 25.1113 22.0002 25.1113C17.0891 25.1113 13.1113 28.3557 13.1113 32.3557"
                    stroke="#FF8A00"
                    stroke-width="2.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
                <div>
                  <p>All Participants</p>
                  <h3>32{data.Totaluser}+</h3>
                </div>
              </div>
              <div
                className="countdown_single"
                data-aos="fade-up"
                data-aos-duration="1000"
                data-aos-delay="300"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="44"
                  height="44"
                  viewBox="0 0 44 44"
                  fill="none"
                >
                  <path
                    d="M17 25.5005C17 27.4405 18.5 29.0004 20.34 29.0004H24.1C25.7 29.0004 27 27.6405 27 25.9405C27 24.1205 26.2 23.4605 25.02 23.0405L19 20.9405C17.82 20.5205 17.02 19.8805 17.02 18.0405C17.02 16.3605 18.32 14.9805 19.92 14.9805H23.68C25.52 14.9805 27.02 16.5405 27.02 18.4805"
                    stroke="#8C64FF"
                    stroke-width="2.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M22 13V31"
                    stroke="#8C64FF"
                    stroke-width="2.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M22 2C10.96 2 2 10.96 2 22C2 29.88 6.56001 36.7 13.2 39.96"
                    stroke="#1E1E1E"
                    stroke-width="2.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M42 22C42 33.04 33.04 42 22 42"
                    stroke="#1E1E1E"
                    stroke-width="2.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M42 9.99999V2H34"
                    stroke="#8C64FF"
                    stroke-width="2.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M32 12L42 2"
                    stroke="#8C64FF"
                    stroke-width="2.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
                <div>
                  <p>Total Earned</p>
                  <h3>{data.totalInterest}+</h3>
                </div>
              </div>
              <div
                className="countdown_single"
                data-aos="fade-up"
                data-aos-duration="1000"
                data-aos-delay="500"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="44"
                  height="49"
                  viewBox="0 0 44 49"
                  fill="none"
                >
                  <path
                    d="M22 15.7148V27.1434"
                    stroke="#00cdf9"
                    stroke-width="2.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M12.8809 9.90913C15.6237 8.51484 18.7094 7.71484 22.0009 7.71484C33.0408 7.71484 42.0008 16.6748 42.0008 27.7148"
                    stroke="#1E1E1E"
                    stroke-width="2.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M22 47.7142C10.96 47.7142 2 38.7543 2 27.7143C2 23.1885 3.50857 19.0285 6.02286 15.6914"
                    stroke="#1E1E1E"
                    stroke-width="2.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M15.1426 2H28.8569"
                    stroke="#00cdf9"
                    stroke-width="2.5"
                    stroke-miterlimit="10"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M28.6289 39.714V37.0626C28.6289 33.794 30.9603 32.4455 33.7946 34.0912L36.0803 35.4169L38.366 36.7426C41.2003 38.3883 41.2003 41.0626 38.366 42.7083L36.0803 44.034L33.7946 45.3597C30.9603 47.0055 28.6289 45.6569 28.6289 42.3883V39.714Z"
                    stroke="#00cdf9"
                    stroke-width="2.5"
                    stroke-miterlimit="10"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
                <div>
                  <p>BTC 24H Change</p>
                  <h3
                    className={
                      duppairlists[0]?.change < 0 ? "txt_red" : "txt_green"
                    }
                  >
                    {toFixedDown(duppairlists[0]?.change, 2)} %
                  </h3>
                </div>
              </div>
            </div>
          </div>
        </section> */}

        <section className="section ledger">
          <div className="container">
            <div className="row">
              <div className="col-lg-11 col-xxl-10 mx-auto">
                <h2 className="h2tag" >How AbandonedCoins works</h2>
                <div className="inbox">
                  <div className="row">
                    <div className="col-lg-4 d-flex" data-aos="fade-up" data-aos-duration="1000">
                      <div className="box">
                        <div>
                          <h5>Assess your situation <br /></h5>
                          <div className="arw" >
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M2 21.7741L22 1.7782M22 1.7782H2M22 1.7782V21.7741" stroke="white" stroke-width="3" stroke-linecap="round" stroke-linejoin="round" /></svg>
                          </div>
                        </div>
                        <p>We will conduct initial assessments to determine the potential for a significant recovery based on our expertise.</p>
                        <div className="img-box">
                          <img src={require("../assets/images/img1.png")} className="img-fluid" alt="img" />
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-4 d-flex" data-aos="fade-up" data-aos-duration="1000" data-aos-delay="100">
                      <div className="box">
                        <div>
                          <h5>Rewards Distribution
                            {/* Compile evidence  */}
                            <br /></h5>
                          <div className="arw" >
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M2 21.7741L22 1.7782M22 1.7782H2M22 1.7782V21.7741" stroke="white" stroke-width="3" stroke-linecap="round" stroke-linejoin="round" /></svg>
                          </div>
                        </div>
                        <p>
                          Any rewards earned during the staking period are typically distributed when you release your stake. These rewards may be transferred separately or together with your original assets.
                          {/* We will gather all necessary information and documentation to effectively pursue your case. */}
                        </p>
                        <div className="img-box">
                          <img src={require("../assets/images/img2.png")} className="img-fluid" alt="img" />
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-4 d-flex" data-aos="fade-up" data-aos-duration="1000" data-aos-delay="300">
                      <div className="box">
                        <div>
                          <h5>Funds Return
                            {/* Confront the parties involved  */}
                            <br /></h5>
                          <div className="arw" >
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M2 21.7741L22 1.7782M22 1.7782H2M22 1.7782V21.7741" stroke="white" stroke-width="3" stroke-linecap="round" stroke-linejoin="round" /></svg>
                          </div>
                        </div>
                        <p>
                          Your staked crypto and any rewards are returned to your wallet, ready for you to use, trade, or invest again.
                          {/* We will systematically address the entities responsible for facilitating the unauthorized transfer of your funds. */}
                        </p>
                        <div className="img-box">
                          <img src={require("../assets/images/img3.png")} className="img-fluid" alt="img" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="blw">
                  <div className="row">
                    <div className="col-lg-12 m-auto" data-aos="fade-up" data-aos-duration="1000" data-aos-delay="100">
                      <h2 className="text-center">"Release your assets with confidence: We are dedicated to our proven success and will relentlessly pursue the recovery of your funds."
                        {/* Reclaim your funds: Rest assured, we are committed to our successful track record and will work tirelessly to recover your money. */}
                      </h2>
                    </div>
                    {/* <div className="col-lg-5" data-aos="fade-up" data-aos-duration="1000" data-aos-delay="300">
                      <div className="mb-4" >
                        <h5>32{data?.Totaluser}+</h5>
                        <p>All Participants</p>
                      </div>
                      <div className="mb-4" >
                        <h5>{data?.totalInterest}+</h5>
                        <p>Total unstaked</p>
                      </div>
                      <div className="mb-4" >
                        <h5>-0.15 %</h5>
                        <p>BTC 24H Change</p>
                      </div>
                      <p>We take pride in our client success stories, where our innovative  strategies and flawless execution have been crucial in achieving their  business goals and exceeding expectations.</p>
                    </div> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>


        <section className="section">
          <div className="container">
            <div className="row align-items-center">
              <div
                className="col-lg-6 zindex"
                data-aos="fade-up"
                data-aos-duration="1000"
              >
                <h2 className="h2tag mb-5">
                  Earning Steady Returns with Crypto Staking
                  {/* Report suspicious activities by online trading brokers and recover your funds */}
                </h2>
                <div>
                  <div className="staking_works" data-aos="fade-up" data-aos-duration="1000" data-aos-delay="100">
                    <div>
                      {/* <h2>Generating Passive Income through Staking</h2> */}
                      <p className="main_para">
                        Generating passive income through staking involves earning rewards by participating in a cryptocurrency network. When you stake your cryptocurrency, you lock up a portion of your assets in a blockchain protocol to help support the network's operations, such as validating transactions or securing the network. In return for your contribution, the network rewards you with additional cryptocurrency, which can accumulate over time without requiring active trading or management. This process allows you to earn a steady income from your crypto holdings while maintaining a long-term investment strategy.
                        {/* If you have invested with a Forex, crypto, CFD, or binary options broker and encountered difficulties withdrawing your funds, you are not alone. Many victims are fighting for justice, and we are here to assist. With our extensive experience, we can identify violations by unregulated trading companies. */}
                      </p>
                    </div>
                  </div>
                  <div className="staking_works" data-aos="fade-up" data-aos-duration="1000" data-aos-delay="300">
                    <div>
                      {/* <h2>Enhancing Staking Returns</h2> */}
                      <p className="main_para">
                        Discover the power of earning passively with crypto staking by exploring how you can leverage your cryptocurrency investments to generate a steady stream of income with minimal effort. Crypto staking involves locking up your digital assets in a blockchain network to support its operations, such as validating transactions or securing the network. In exchange for your participation, you receive rewards in the form of additional cryptocurrency. This process not only helps enhance the stability and functionality of the network but also allows you to accumulate earnings over time without actively trading or managing your assets.
                        {/* Understanding how to investigate suspicious transactions and locate the responsible parties is crucial in today's financial landscape. Trust us to help you navigate this challenging situation and retrieve your money. */}
                      </p>
                    </div>
                  </div>
                  {/* <div className="staking_works" data-aos="fade-up" data-aos-duration="1000" data-aos-delay="500">
                    <div>
                      <h2>Deciphering Staking Rewards</h2>
                      <p className="main_para">Exploring How Staking Generates Profits in the Cryptocurrency Realm</p>
                    </div>
                  </div> */}
                </div>
              </div>
              <div
                className="col-lg-6 d-none d-lg-block"
                data-aos="fade-up"
                data-aos-duration="1000"
                data-aos-delay="300"
              >
                <div className="stakingimg_box">
                  {theme === "light" ? (
                    <img
                      src={require("../assets/images/img_02_dark.png")}
                      className="img-fluid"
                      alt="Light Image"
                    />
                  ) : (
                    <img
                      src={require("../assets/images/img_02_dark.png")}
                      className="img-fluid"
                      alt="Dark Image"
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="section">
          <div className="container">
            <div className="row">
              <div
                className="col-lg-10 mx-auto"
                data-aos="fade-up"
                data-aos-duration="1000"
              >
                <Slider {...ticker_settings} className="ticker_settings">
                  {currencyData &&
                    currencyData.data &&
                    currencyData.data.length > 0 &&
                    currencyData.data.map((item, i) => {
                      return (
                        <div>
                          <div className="ticker_panel">
                            <img
                              src={item.image}
                              alt="Crypto"
                              className="img-fluid"
                            />
                            <div className="ticker_content">
                              <h3>{item.coin}</h3>
                              <h4>
                                $ {toFixedDown(item.USDValue, item.decimals)}
                              </h4>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                </Slider>
              </div>
            </div>
          </div>
        </section>

        {/* <section className="section">
          <div className="container">
            <div className="row align-items-center">
              <div
                className="col-lg-6 col-xl-6 d-none d-lg-block"
                data-aos="fade-up"
                data-aos-duration="1000"
              >
                {theme === "light" ? (
                  <img
                    src={require("../assets/images/img_01.png")}
                    className="img-fluid"
                    alt="Light Image"
                  />
                ) : (
                  <img
                    src={require("../assets/images/img_01_dark.png")}
                    className="img-fluid"
                    alt="Dark Image"
                  />
                )}
              </div>
              <div
                className="col-xl-6 col-lg-6 text-lg-start text-center"
                data-aos="fade-up"
                data-aos-duration="1000"
              >
                <h2 className="main_title">
                  How <span></span> works
                </h2>
                <p className="main_para">
                  <b>Stake Your Cryptocurrency:</b> Choose a cryptocurrency that
                  supports staking, acquire it, and transfer it to a compatible
                  staking platform or wallet.
                </p>
                <p className="main_para">
                  <b>Participate in :</b> Your staked cryptocurrency
                  helps secure the blockchain network and supports its
                  operations. Your involvement in network activities earns you
                  staking rewards based on your stake's size and the network's
                  rules.
                </p>
                <p className="main_para">
                  <b>Manage and Monitor:</b> Regularly review your staking
                  performance, track your rewards, and stay informed about any
                  changes in the network. Adjust your strategy as necessary to
                  make the most of your staking experience.
                </p>
                <button
                  onClick={() => navigate(isAuth ? "/staking" : "/login")}
                  className="primary_btn primary_btn_with_bg primary_btn_transform"
                >
                  Stake Now
                </button>
              </div>
            </div>
          </div>
        </section> */}


        {/* <StakingCal /> */}
        <Faq />
      </div>
      <Footer />
      {/* <NavbarFixed /> */}
    </div>
  );
}
