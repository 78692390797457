import React, { useState, useEffect } from "react";

// import api
import { getFaq } from "../../api/users";

const Faq = () => {
  
  // state
  const [data, setData] = useState([]);

  //function
  const getData = async () => {
    try {
      const { status, result } = await getFaq();
      if (status === "success") {
        setData(result);
      }
    } catch (err) {
      console.log(err, 'error')
    }
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <section className="section home_faq">
      <div className="container">
        <div className="row">
          <div className="col-lg-8 mx-auto">
            <h2
              className="h2tag text-center"
              data-aos="fade-up"
              data-aos-duration="1000"
            >
              Frequently Asked Questions
            </h2>
            <div class="accordion faq_accordian" id="faq_accordian">
              {data &&
                data.length > 0 &&
                data.map((item, key) => {
                  return (
                    <div
                      key={key}
                      class="accordion-item"
                      data-aos="fade-up"
                      data-aos-duration="1000"
                    >
                      <h2 class="accordion-header" id={"headingOne" + key}>
                        <button

                          class={
                            key == 0
                              ? "accordion-button"
                              : "accordion-button collapsed"
                          }
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target={"#collapseOne" + key}
                          aria-expanded="true"
                          aria-controls={"collapseOne" + key}
                        >
                          {item.question}
                        </button>
                      </h2>
                      <div
                        id={"collapseOne" + key}
                        class={
                          key == 0
                            ? "accordion-collapse collapse show"
                            : "accordion-collapse collapse"
                        }
                        aria-labelledby={"headingOne" + key}
                        data-bs-parent="#faq_accordian"
                      >
                        <div class="accordion-body">
                          <p className="main_para">{item.answer}</p>
                        </div>
                      </div>
                    </div>
                  );
                })}
              {data.length < 0 && <>There are no records to display</>}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Faq;
